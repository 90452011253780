/* ==================================================================================== *
 *
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 * & https://roots.io/sage/
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * ==================================================================================== *
 *
 * TweenMax
 * By GreenStock
 * https://greensock.com/tweenmax
 * https://ihatetomatoes.net/wp-content/uploads/2016/07/GreenSock-Cheatsheet-4.pdf
 *
 * ==================================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var aands = {
    // All pages
    'common': {
      init: function() {
        //Mega Mebu Responsive
        $('.sub-menu-wrapper').addClass('is-hidden');
        $(document).on('click', '.menu-item-has-children span.mobile-arrow', function() {
          if (!$(window).width() <= '1199') {
            if ($(this).hasClass('sub-menu-open')) {
              if ($(this).parent().parent().hasClass('sub-menu')) {
                $(this).toggleClass('sub-menu-open').next(".mega-menu-wrapper").stop().slideUp(500);
                $(this).parent('.menu-item-has-children').removeClass('sub-menu-main-open');
                $(this).removeClass('sub-menu-open');
              } else {
                $(this).removeClass('sub-menu-open').next(".sub-menu-wrapper").stop().slideUp(500);
                // $('span.mobile-arrow').toggleClass('sub-menu-open').next(".mega-menu-wrapper").stop().slideUp(500);
                // $('.menu-item-has-children').removeClass('sub-menu-main-open');
                // $(this).toggleClass('sub-menu-open').next(".sub-menu-wrapper").stop().slideToggle(500);
                // $('.mobile-arrow').removeClass('sub-menu-open');
              }

            } else {
              if ($(this).parent().parent().hasClass('sub-menu')) {
                // $( this ).parents( '.sub-menu' ).find( '.sub-menu-main-open .mobile-arrow' ).trigger( 'click' );
                $(this).parent('.menu-item-has-children').toggleClass('sub-menu-main-open');
                $(this).toggleClass('sub-menu-open').next(".mega-menu-wrapper").stop().slideDown(500);
              } else {
                $(this).addClass('sub-menu-open').next(".sub-menu-wrapper").stop().slideDown(500);
                // $('.menu-item-has-children').removeClass('sub-menu-main-open');
                // $('.mobile-arrow').removeClass('sub-menu-open');
                // $(this).parent('.menu-item-has-children').toggleClass('sub-menu-main-open');
                // $(this).toggleClass('sub-menu-open').next(".sub-menu-wrapper").stop().slideToggle(500);
              }
            }
          }
        });


        $(document).on('click', '.container-fluid .navbar-toggler', function() {
            if (!$(window).width() <= '1199') {
                $('body').toggleClass('navbar-collapse-show');
            }
        });
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home/Index page example - if WordPress, 'index' will need to be changed to 'home'
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
    // ...
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = aands;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  // Load Events
  $(document).ready(UTIL.loadEvents);

  //SHARING
  var screenWidth = window.innerWidth;
  var screenHeight = window.innerHeight;

  var w = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  var h = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  var left = ((screenWidth / 2) - 300);
  var top = ((screenHeight / 2) - 150);

  $('.fbsharelink').click(function()
  {
      var shareurl = $(this).data('shareurl');
      window.open('https://www.facebook.com/sharer/sharer.php?u='+escape(shareurl)+'&t='+document.title, '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      return false;
  });
  $('.twsharelink').click(function()
  {
      var twshareurl = $(this).data('shareurl');
      var twsharetitle = $(this).data('sharetitle');
      window.open('http://twitter.com/share?text='+twsharetitle+'&url='+twshareurl+'', '_blank', 'width=600,height=300,top='+top+',left'+left+'');
      return false;
  });
  $('.lisharelink').click(function() {
      var lishareurl = $(this).data('shareurl');
      var lisharetitle = $(this).data('sharetitle');
      window.open('https://www.linkedin.com/shareArticle?mini=true&url='+lishareurl+'&title='+lisharetitle+'&source=AandS&target=new', '_blank', 'width=600,height=300,top='+ top +',left='+ left +'');
      return false;
  });
  // Houzz button styling
  var houzzFrame = $('.social .houzz-wrap iframe').contents();
  houzzFrame.find('a').hide();
  // v$fear houzzImg = $('.product-for .slick-active img').attr('src');
  // console.log(houzzImg);
  // $('.product-nav button').click(function(e){
  //     var houzzImg = $('.product-for .slick-active img').attr('src');
  //     console.log(houzzImg);
  // });

  //COLOUR SELECTOR
  var frame = 'white',
      roof = 'clear',
      view = 'side',
      base = $('#cs-image-main').data('source');

  $('.frame button').click(function(){
      frame = $(this).attr('id');
      // $('#image-controller').attr('data-frame', frame);
      $('.frame button').removeClass('btn-ruby');
      $(this).addClass('btn-ruby');
  });

  $('.roof button').click(function(){
      roof = $(this).attr('id');
      $('.roof button').removeClass('btn-ruby');
      $(this).addClass('btn-ruby');
  });

  $('.view button').click(function(){
      view = $(this).attr('id');
      $('.view button').removeClass('btn-ruby');
      $(this).addClass('btn-ruby');
  });

  $('.colour-selector button').click(function() {
      console.log('clicked');
      $('#cs-image-main').attr('src', ''+base+'cs-visuals/'+roof+'/'+frame+'-'+view+'.jpg');
  });

  $('a.see-all-cat-items').click(function(event) {
      event.preventDefault();
      var prefix = $(this).data('prefix');
      var seeAllBtn = $(this);

      if (typeof prefix !== typeof undefined && typeof prefix !== typeof false) {
          $('.item-' + prefix).each(function(index, element) {
              $(this).delay(150 * index).slideDown(250);
          });

          seeAllBtn.hide();
      }
  });

  //DYNAMICALLY LOAD VIDEOS
  var videoLoad = function(){
      (function() {
          // var ov = document.getElementsByClassName("video");
          // for (var n = 0; n < ov.length; n++) {
          //     var p = document.createElement("div");
          //     p.innerHTML = labnolThumb(ov[n].dataset.id, ov[n].dataset.vtitle);
          //     p.onclick = labnolIframe;
          //     ov[n].appendChild(p);
          // }
          
          var v = document.getElementsByClassName("featured-video");
          for (var n = 0; n < v.length; n++) {
              var p = document.createElement("div");
              p.innerHTML = labnolThumbLarge(v[n].dataset.id, v[n].dataset.vtitle);
              p.onclick = labnolIframe;
              v[n].appendChild(p);
          }
      })();
      
      (function() {
          var ytv = document.getElementsByClassName("banner-youtube");
          for (var n = 0; n < ytv.length; n++) {
              var p = document.createElement("div");
              p.innerHTML = labnolThumb(ytv[n].dataset.id, '');
              p.onclick = labnolIframe;
              ytv[n].appendChild(p);
          }
      })();
      
      $('.banner-youtube').click(function(){
          $(this).addClass('active');
      });
      
      function labnolThumb(id, altText) {
          return '<img class="thumb" src="//img.youtube.com/vi/' + id + '/sddefault.jpg" alt="' + altText + '"><div class="play-icon"></div>';
      }
      
      function labnolThumbLarge(id, altText) {
          return '<img class="thumb" src="//img.youtube.com/vi/' + id + '/maxresdefault.jpg" alt="' + altText + '"><div class="play-icon"></div>';
      }
      
      function labnolIframe() {
          var iframe = document.createElement("iframe");
          if (this.classList.contains('testimonial-video')) {
              if (this.parentNode) {
                  iframe.setAttribute("src", "//www.youtube.com/embed/" + this.parentNode.dataset.id + "?autoplay=1&modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&controls=0");
              }
          } else {
              if (this.parentNode) {
                  iframe.setAttribute("src", "//www.youtube.com/embed/" + this.parentNode.dataset.id + "?autoplay=1&rel=0&autohide=1&border=0&wmode=opaque&enablejsapi=0&controls=1&showinfo=0&modestbranding=1");
              }
          }
          iframe.setAttribute("frameborder", "0");
          iframe.setAttribute("allowfullscreen", "");
          iframe.setAttribute("class", "video-frame");
          iframe.setAttribute("loop", "1");
          if (this.parentNode) {
              this.parentNode.replaceChild(iframe, this);
          }
      }
      
      if ( $('.banner-youtube').hasClass('autoplay') ) {
          $('.banner-youtube').addClass('active');
          $('.banner-youtube').find('div').trigger("click");
      }
  };
  videoLoad();

  $(function(){
      if ( window.location.hash != '' ) {
          $('.collapse').removeClass('in');
          $(window.location.hash + '.collapse').collapse('show');
      }
  });

})(jQuery); // Fully reference jQuery after this point.
